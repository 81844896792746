<template>
  <el-container class="content">
    <el-aside width="199px">
      <el-menu
        :default-active="$route.path"
        exact
        class="el-menu-vertical"
        background-color="#041527"
        text-color="#ada7b4"
        active-text-color="#ffffff"
        @open="handleOpen"
        @close="handleClose"
        router
      >
        <el-menu-item index="/salaryList">
          <i class="el-icon-date"></i>
          <template #title>员工薪酬列表</template>
        </el-menu-item>
        <el-menu-item index="/companyList">
          <i class="el-icon-setting"></i>
          <template #title>公司字段控制</template>
        </el-menu-item>
        <!-- <el-menu-item index="/programClassification">
          <i class="el-icon-setting"></i>
          <template #title>平台节目分类表</template>
        </el-menu-item>
        <el-menu-item index="/oprogramList">
          <i class="el-icon-setting"></i>
          <template #title>平台节目表</template>
        </el-menu-item>
        <el-menu-item index="/companyList">
          <i class="el-icon-setting"></i>
          <template #title>平台机构列表</template>
        </el-menu-item>
        <el-menu-item index="/eteacher">
          <i class="el-icon-setting"></i>
          <template #title>机构老师列表</template>
        </el-menu-item>
        <el-menu-item index="/eprogramList">
          <i class="el-icon-setting"></i>
          <template #title>机构节目列表</template>
        </el-menu-item>
        <el-menu-item index="/onLiveList">
          <i class="el-icon-setting"></i>
          <template #title>主播任务表</template>
        </el-menu-item>
        <el-menu-item index="/onlive">
          <i class="el-icon-setting"></i>
          <template #title>主播面板</template>
        </el-menu-item> -->
      </el-menu>
    </el-aside>
    <el-container>
      <el-header>
        <div class="elheaderleft">全诚人力薪酬管理系统</div>
        <div class="elheaderright" @click="logout">管理员，退出</div>
      </el-header>
      <el-main>
        <router-view/>
      </el-main>
    </el-container>
  </el-container>
</template>

<script type="text/javascript">
import { post } from '../utils/request'
export default {
  name: 'Layout',
  methods: {
    handleOpen (key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose (key, keyPath) {
      console.log(key, keyPath)
    },
    logout () { // 退出登录
      var _this = this
      this.$confirm('是否确定退出当前系统?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _this.logoutFn()
      }).catch(() => {
        this.$message({ type: 'info', message: '已取消' })
      })
    },
    async logoutFn () {
      const result = await post('/api/v1/admin/info/logout', { })
      if (result.code === '0') {
        this.$message({ type: 'success', message: '退出成功!' })
        sessionStorage.clear()
        location.reload()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .content {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .el-header {
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .elheaderleft {
      line-height: 60px;
      font-size: 30px;
    }
    .elheaderright {
      color: #041527;
      cursor: pointer;
    }
    .elheaderright:hover {
      color: #489ef8;
    }
  }
  .el-aside {
    background-color: #041527;
    padding-top: 60px;
    .el-menu-vertical {
      width: 200px;
      .el-menu-item.is-active {
        background-color: #4091f7 !important;
      }
    }
  }
  .el-main {
    position: relative;
    background-color: #f4f4f5;
  }
</style>
