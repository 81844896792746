import axios from 'axios'
import { useRouter } from 'vue-router'
import store from './../store/index'

const instance = axios.create({
  baseURL: 'https://www.qchrs.com',
  timeout: 10000
})

if (store.state.salaryToken) {
  instance.interceptors.request.use(function (config) {
    // Do something before request is sent
    if (store.state.salaryToken) {
      config.headers.salaryToken = store.state.salaryToken
      return config
    }
  }, function (error) {
    // Do something with request error
    return Promise.reject(error)
  })
}

export const get = (url, params = {}) => {
  const router = useRouter()
  return new Promise((resolve, reject) => {
    instance.get(url, { params }).then((response) => {
      resolve(response.data)
    }, err => {
      reject(err)
    })
  }).catch((e) => {
    if (e.message === 'Request failed with status code 401') {
      router.push({ name: 'index' })
    }
  })
}

export const post = (url, data = {}) => {
  const router = useRouter()
  return new Promise((resolve, reject) => {
    instance.post(url, data, {
      headers: { 'Content-Type': 'application/json' }
    }).then((response) => {
      resolve(response.data)
    }, err => {
      reject(err)
    })
  }).catch((e) => {
    if (e.message === 'Request failed with status code 401') {
      router.push({ name: 'index' })
    }
  })
}

export const deletes = (url, params = {}) => {
  return new Promise((resolve, reject) => {
    instance.delete(url, { params }, {
      headers: { 'Content-Type': 'x-www-form-urlencoded' }
    }).then((response) => {
      resolve(response.data)
    }, err => {
      reject(err)
    })
  })
}

export const patch = (url, data = {}) => {
  return new Promise((resolve, reject) => {
    instance.patch(url, data, {
      headers: { 'Content-Type': 'application/json' }
    }).then(response => {
      resolve(response.data)
    }).catch(err => {
      reject(err)
    })
  })
}
