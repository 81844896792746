import { createRouter, createWebHashHistory } from 'vue-router'
import Layout from '@/layout'

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import(/* webpackChunkName: "eindex" */ '../views/index/index'),
    beforeEnter (to, from, next) {
      const salaryToken = sessionStorage.getItem('salaryToken')
      salaryToken ? next({ name: 'SalaryList' }) : next()
    }
  },
  {
    path: '/salaryList',
    component: Layout,
    redirect: '/salaryList',
    children: [
      {
        path: '/salaryList',
        name: 'SalaryList',
        component: () => import(/* webpackChunkName: "oteacher" */ '../views/salaryList/index')
      },
      {
        path: '/companyList',
        name: 'CompanyList',
        component: () => import(/* webpackChunkName: "oteacher" */ '../views/companyList/index')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path === '/') {
    next()
  } else {
    const salaryToken = sessionStorage.getItem('salaryToken')
    if (salaryToken === null || salaryToken === '') {
      next({ name: 'index' })
    } else {
      next()
    }
  }
})

export default router
