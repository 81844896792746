import { createStore } from 'vuex'

export default createStore({
  state: {
    salaryToken: sessionStorage.getItem('salaryToken') || ''
  },
  mutations: {
    setSalaryToken (state, salaryToken) {
      sessionStorage.setItem('salaryToken', salaryToken)
      state.salaryToken = salaryToken
    }
  },
  actions: {
    setSalaryTokenAction (context, salaryToken) {
      context.commit('setSalaryToken', salaryToken)
    }
  },
  modules: {
  }
})
